import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import Screenshot from "../../../components/screenshot"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"
import ExternalLink from "../../../components/externallink"
import SubText from "../../../components/subtext"

import { useTheme } from "../../../services/theme"


const WebflowCustomAttributeFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I add the Vimkit custom attribute to a Webflow form?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    maxWidth="960px"
                    title="How do I add the Vimkit custom attribute to a Webflow form?"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="480px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Get the form's custom attribute</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Websites' and choose the website you want to add the custom attribute to.<br/><br/>
                        &#8594;Choose the form that you want to use.<br/><br/>
                    </SegmentText>
                    <SegmentSubTitle id="customattribute">Step 2: Add the custom attribute to the form on Webflow</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">                        
                        &#8594;Go to Webflow, select the form element and add a custom attribute. See 
                        <ExternalLink 
                            to="https://university.webflow.com/article/how-to-add-custom-attributes-to-an-element-in-webflow#custom-attributes"
                            display="inline"
                        >
                            &nbsp;Webflow's documentation
                        </ExternalLink> for details.
                        <SubText align="left">Make sure you've selected the 'Form' element - not the Form block element.</SubText><br/>
                        &#8594;Copy and paste the the custom attribute name and value from Vimkit on Webflow and click save.  
                    </SegmentText>
                    <Screenshot fluid={data.customAttributeScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="40px"/>
                    <SegmentSubTitle>Step 3: Finally, publish your Webflow site and check if the data attribute was added correctly.</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Click publish on your Webflow site (see 
                        <ExternalLink 
                            display="inline" 
                            to="https://university.webflow.com/article/publish-your-project#publish-the-whole-project"
                        >
                         &nbsp;Webflow's documentation
                        </ExternalLink> for details). 
                        <SubText align="left">If you host your site somewhere else, export the code from Webflow
                         and follow your standard process to publish your website.</SubText><br/>
                        &#8594;Enter the full URL of the page where you are using the form and click 'Verify' on Vimkit.
                         You'll see a confirmation if the custom attribute has been added correctly.
                    </SegmentText>
                    <Screenshot fluid={data.verifyFormScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
            
        </SiteLayout>    
    )
}

export default WebflowCustomAttributeFaq

export const query = graphql`
    query {
        customAttributeScreenshot: file(relativePath: { eq: "screenshots/customattribute.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        verifyFormScreenshot: file(relativePath: { eq: "screenshots/verifyform.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`